import React from 'react'

import { HowItWorksContainer, HowItWorksCard, HowItWorksWrapper, HowItWorksIcon, HowItWorksH1, HowItWorksH2,  HowItWorksUl,  HowItWorksLi, HowItWorksNo } from './HowItWorksElements'
const HowItWorks = () => {
    return (
        <HowItWorksContainer>
            <HowItWorksH1>How It Works</HowItWorksH1>
            <HowItWorksWrapper>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>01</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>GATHERING OF INFORMATION</HowItWorksH2>
                    <HowItWorksUl>
                        <HowItWorksLi>Purpose of the business</HowItWorksLi>
                        <HowItWorksLi>Pain points that needs to be addressed</HowItWorksLi>
                        <HowItWorksLi>Objectives and goals</HowItWorksLi>
                        <HowItWorksLi>Technical requirements</HowItWorksLi>
                        <HowItWorksLi>Working budget</HowItWorksLi>
                        <HowItWorksLi>Pegs</HowItWorksLi>
                    </HowItWorksUl>
                </HowItWorksCard>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>02</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>SCOPING/PLANNING</HowItWorksH2>
                        <HowItWorksUl>
                        <HowItWorksLi>Structure of the website</HowItWorksLi>
                            <HowItWorksLi>Site map (navigation)</HowItWorksLi>
                            <HowItWorksLi>Style of design/themes</HowItWorksLi>
                            <HowItWorksLi>Placement of elements</HowItWorksLi>
                            <HowItWorksLi>User interface</HowItWorksLi>
                            <HowItWorksLi>User experience</HowItWorksLi>
                            <HowItWorksLi> Design of mockup home page and 1 internal page </HowItWorksLi>
                        </HowItWorksUl>
                </HowItWorksCard>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>03</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>DESIGN</HowItWorksH2>
                    <HowItWorksUl>
                        <HowItWorksLi>1-2 more mock up home page and 1 internal page</HowItWorksLi>
                            <HowItWorksLi>Final home page and the entire system will be designed</HowItWorksLi>
                            <HowItWorksLi>2 minor edits allowed</HowItWorksLi>
                            <HowItWorksLi>Final design of front and backend</HowItWorksLi>
                            <HowItWorksLi>User interface</HowItWorksLi>
                            <HowItWorksLi>User experience</HowItWorksLi>
                            <HowItWorksLi> Design of mockup home page and 1 internal page </HowItWorksLi>
                        </HowItWorksUl>
                </HowItWorksCard>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>04</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>DEVELOPMENT</HowItWorksH2>
                    <HowItWorksUl>
                        <HowItWorksLi>Creation and coding of all website/app/system pages, Programming site functions and features, hosting setup, installations, integrations.</HowItWorksLi>
                        </HowItWorksUl>
                </HowItWorksCard>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>05</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>TESTING AND ACCEPTANCE</HowItWorksH2>
                    <HowItWorksUl>
                        <HowItWorksLi>Internal testing and retesting of the site using multiple browsers and devices.</HowItWorksLi>
                            <HowItWorksLi>2 UAT testing with client; 2nd testing for approval.</HowItWorksLi>
                        </HowItWorksUl>
                </HowItWorksCard>
                <HowItWorksCard>
                    <HowItWorksIcon><HowItWorksNo>06</HowItWorksNo></HowItWorksIcon>
                    <HowItWorksH2>LAUNCH AND MAINTENANCE</HowItWorksH2>
                    <HowItWorksUl>
                        <HowItWorksLi>Internal testing and retesting of the site using multiple browsers and devices</HowItWorksLi>
                        </HowItWorksUl>
                </HowItWorksCard>
            </HowItWorksWrapper>
        </HowItWorksContainer>
    )
}

export default HowItWorks