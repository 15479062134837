import React from 'react';
import { HeaderContainer, HeaderContenth1, HeaderContenth2 } from './HeaderElement';

const HeaderSection = () => {
    return (
       
        <HeaderContainer id="home">
        
                <HeaderContenth1>purple giant</HeaderContenth1>
                <HeaderContenth2>solutions, inc.</HeaderContenth2>
            
        </HeaderContainer>
    );
};

export default HeaderSection;
