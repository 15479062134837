import React, {useState}  from "react";

import HeaderSection from "../components/HeaderSection";
import Navbar from "../components/Navbar";
import Sidebar  from "../components/Sidebar";
import InfoSection from "../components/InfoSection";
import { homeObjOne, homeObjTwo, homeObjThree } from "../components/InfoSection/Data";
import Services from "../components/Services";
import HowItWorks from "../components/HowItWorks";
/* import SampleWorks from "../components/SampleWorks"; */
import Footer from "../components/Footer";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    const toggle = () =>{
        setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle ={toggle}/>
            <HeaderSection />
            <InfoSection {...homeObjOne} />
            <Services />
            <InfoSection {...homeObjTwo} />
            <HowItWorks />
            {//<SampleWorks />//
            /* <InfoSection {...homeObjThree} /> */}
            <Footer />
        </>
    );
};

export default Home;
