import React from 'react'
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper,
Topline, Heading, Subtitle, ImgWrap, Img} from './InfoElements'



const InfoSection = ({id, imgStart, topline, headline, description, description2, description3, description4, description5, img, alt, marginStart}) => {

    return (
        <InfoContainer id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper marginStart={marginStart}>
                            <Topline>{topline} </Topline>
                            <Heading>{headline}</Heading>
                            <Subtitle >{description} <br/><br/> {description2} <br/><br/> {description3} <br/><br/> {description4} <br/><br/> {description5}</Subtitle>

                        </TextWrapper>
                    </Column1>
                    <Column2>
                    <ImgWrap>
                    <Img src={img} alt={alt}/>
                    </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    )
}

export default InfoSection
