import styled from "styled-components";

export const HowItWorksContainer = styled.div`
width: 100%;
height:1700px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #f5edfa;
margin-top:5rem;


@media screen and (max-width: 768px){
    height: 3750px;
}

@media screen and (max-width: 480px){
    height: 3750px;
    margin-top:180px;
}
`;

export const HowItWorksWrapper=styled.div`
max-width:1200px;
margin:0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
grid-gap: 16px;
padding:0 50px;
margin-bottom:100px;

@media screen and (max-width:1000px){
    grid-template-columns: 1fr 1fr;

}

@media screen and (max-width:768px){
    grid-template-columns: 1fr;
    padding:0 20px;
}


`;

export const HowItWorksCard = styled.div`
font-family: Avenir LT Pro;
font-weight:35 Light;
background:#fff;
display: flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
border-radius: 10px;
height:600px;
padding:30px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
transition: all 0.2s ease-in-out;
width:290px;

&:hover{
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor:pointer;
}

@media screen and (max-width:768px){
    width:280px;
    margin-bottom:10px;

}
`;
export const HowItWorksIcon = styled.div`
height: 160px;
width: 160px;
margin: -20px 0 15px 0;

text-align:center;
`;

export const HowItWorksNo = styled.h1`
color:#8051a2;
font-family:Avenir LT Pro;
font-weight:35 light;
font-size:90px;
letter-spacing:6px;

`;

export const HowItWorksH1 = styled.h1`
 font-size:45px;
 font-family: Avenir LT Pro;
 font-weight: 900;
 color:#8051a2;
 margin-bottom: 64px;
 text-transform: uppercase;

 @media screen and (max-width: 480px){
     font-size:2rem;
 }
`;

export const HowItWorksH2 = styled.h2`
 font-size: 1.4rem;
 margin-bottom:10px;
 color:#666666;
 text-align: center;
`;


export const HowItWorksUl = styled.ul`
list-style-type:disc;
line-height:200%;
color:#7a7979;
`;

export const HowItWorksLi = styled.li`
font-size:1.1rem;


`;


