import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
background-image: linear-gradient(to right, #8051a2, #6743a5    );
    height: 80px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position:sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height:80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;


export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    transform: translate(-100, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style:none;
text-align: center;
margin-right: -22px;
@media screen and (max-width: 768px){
    display:none;
}
`;

export const NavItem=styled.li`
height: 80px;
`;

export const NavLinks=styled(LinkS)`
color:#fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
font-family:Poppins;
font-size:16px;


&.active{
    border-bottom: 3px solid #01bf71;
}
`;

export const Img = styled.img`
height: 100%;
width: 160px;
`;