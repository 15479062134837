import styled from 'styled-components'

export const InfoContainer = styled.div`
color:#fff;
margin-top:-120px;
margin-bottom:100px;

@media screen and (max-width: 768px){
margin-top:-50px;
}


`;

export const InfoWrapper = styled.div`
display: grid;
z-index:1;
height:70%;
width:100%;
max-width:1100px;
margin-left: auto;
margin-right: auto;
padding: 0 0px;
justify-content:center;


@media only screen and (max-width:768px){
      height: auto;
      overflow:hidden;
}
`;

export const InfoRow =styled.div`
margin-top:50px;
display:grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width:768px){
    grid-template-areas: 'col2 col2' 'col1 col1';
   
}
`;

export const Column1 =styled.div`
margin-bottom:0px;
padding: 0 15px;
grid-area: col1;

@media screen and (max-width:768px) {
    width: 300px;
}
`;

export const Column2 =styled.div`
margin-bottom:15px;
padding: 0 15px;
grid-area: col2;

@media screen and (max-width:768px) {
    width: 300px;
    margin-top:0px;
    margin-bottom:15px;
}
`;

export const TextWrapper = styled.div`
max-width:540px;
padding-top: 0;
// padding-bottom: 60px;
margin-left:${({marginStart}) => (marginStart ? '120px' : '0px')};

@media screen and (max-width:768px) {
    margin-left: 0;
}
`;

export const Topline =styled.p`
color:#6743a5;
font-family: Avenir LT Pro;
font-size:45px;
letter-spacing:31px;
line-height:1.1;
font-weight: 900;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 0rem;



@media screen and (max-width:768px) {
    font-size: 30px;
    margin-bottom: 3rem;
    text-align:center;
}
`;



export const Heading =styled.p`
color:rgb(184, 1, 184);
font-size:25px;
font-family: Avenir LT Pro;
font-weight:35 Light;
letter-spacing:38px;
line-height:29px;
font-weight: 600;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 2rem;

@media screen and (max-width:768px) {
    font-size: 18px;
    text-align:center;
 
}

`;


export const Subtitle =styled.p`
max-width:700px;
color:#7a7979;
font-family: Avenir LT Pro;
font-weight:35 Light;
font-size:18px;
letter-spacing:0;
line-height:36px;
margin-bottom: 35px;

@media screen and (max-width:768px) {
    font-size: 16px;
    text-align:center;
}

`;

export const ImgWrap =styled.div`
max-width:500px;
height: 100%;
`;

export const Img =styled.img`
margin: 130px 0 10px 0;
width: 150%;
margin-left: -90px;
padding-right:0;

@media screen and (max-width:768px) {
    margin-left:-60px;
    width: 150%;
}
@media screen and (max-width:640px) {
    margin-left:0px;
    width: 100%;
}
`;

