import image from '../../images/Asset 3@300x.png';
import image2 from '../../images/Asset 1@300x.png';

export const homeObjOne = {
    id: 'about',
    topline: 'Who are We',
    headline: '',
    description: ' In Purple Giant, every idea and solution is provided.',
    description2:'We are an IT-enabled company that is driven by the dedication to pursue and provide innovative business solutions to our clientele. This is done through careful integration of both technology and other field approaches to better business processes that are either for short or long term needs',
    description3:'We belong to a group of companies and this advantage in inclusivity allows us to utilize expertise in the fields of marketing, business development, management, and promotions for our clients and bring about holistic solutions to their respective needs',
    description4:'We are focused on creating systems that would help in the automation process for guaranteed efficiency in outputs and generate real-time reports that is currently essential in a data-driven world. ',
    description5:'It is imperative, that while you focus on your other core competencies, we exist as: Your maintenance, your sustenance, and your guidance.',
    imgStart:false,
    marginStart:false,
    img: image2,
    alt: 'laptop',
}


export const homeObjTwo = {
    id: '',
    topline: 'Technology Competencies',
    headline: '',
    description: ' We develop software applications for Windows, Linux/Unix, OS, iOS, Android OS.',
    description2:'•  HTML5, CSS3, JavaScript, AJAX, jQuery, jQuery UI, System API Integration',
    description3:'•  PHP,  XML, JSON, SOAL, REST, Apache, Java, Xcode',
    description4:'•  MySQL, SQLite, MS SQL Server',
    description5:'',
    imgStart:true,
    marginStart:true, 
    img: image,
    alt: 'Technology',
    
}
{/*
export const homeObjThree = {
    id: '',
    dark: "false",
    topline: 'Data Management and Reporting System',
    headline: '',
    description: 'We have created an customized ERP for a business that needed to distribute their items across different branches, have real time inventory monitoring and request for replenishment of low stocks, and monitor sales movement. This platform have multiple users and access — for the top management, branch heads, and sales team.',
    description2: 'Top management can access via a dashboard summary of the entire operations. These individual details are access to the details of the figure.',
    description3: 'For the branches, the system detects low supply and automatically creates a PO process stock transfers. In replenishing stocks, multiple sources of goods are also in the database so the user just needs to click which supplier is closest to serve it.',
    description4:'The system also monitors sales promotions — in this sample work, they wanted to monitor the discounts provided for items.',
    description5:'All reports can be seen real-time online depending on the different users and are able to downloaded via excel format.',
    
    imgStart:false,
    marginStart:false,
    img: image2,
    alt: 'Data',
*/}

