import styled from 'styled-components'


export const HeaderContainer =styled.div`
background-image: linear-gradient(to right, #8051a2 , #6743a5  );

display:flex;
justify-content:center;
align-items: center;
padding: 0 30px;
height:500px;
position: relative;
top:-160px;
z-index: 1;

@media screen and (max-width:768px) {
    height:850px;
}

@media screen and (max-width:640px) {
    height:100vh; 
    margin-bottom:0px;
    top:0px;
}

`;


export const HeaderContenth1 = styled.h1`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 111px;
letter-spacing:10px;
display: flex;
text-align: center;
margin-left: 24px;
font-weight: Heavy;
font-family: Avenir LT Pro ;
text-decoration: none;
position: absolute;
top:2.5rem;
text-transform:Uppercase;
margin-top: 180px;

@media screen and (max-width:768px) {
    font-size: 40px;
    top:15rem;
    letter-spacing:5px;
}
@media screen and (max-width:640px) {
    font-size:38px;
    top:5.5rem;
    margin-left: 0px;
    letter-spacing:5px;
}

`;

export const HeaderContenth2 = styled.h2`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size:38px;
display: flex;
align-items: center;
margin-left: 24px;
font-weight:Extra Light;
letter-spacing:34px;
font-family: Avenir LT Pro;
text-decoration: none;
position: absolute;
top:12rem;
text-transform:Uppercase;
margin-top: 155px;

@media screen and (max-width:768px) {
    font-size:18px;
    top:20rem; 
}

@media screen and (max-width:640px) {
    font-size:18px;
    top:25rem;
    margin-left: 0px;
    top:10.5rem;
    letter-spacing:10px;
}

`;