import React from 'react'
import Icon1 from "../../images/icon1.svg"
import Icon2 from "../../images/icon2.svg"
import Icon3 from "../../images/icon3.svg"

import { ServicesContainer, ServicesCard, ServicesWrapper, ServicesIcon, ServicesH1, ServicesP, ServicesH2, ServicesUl, ServicesLi, ServicesUlWrapper} from './ServicesElements'
const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}></ServicesIcon>
                    <ServicesH2>ENTERPRISE APPLICATIONS</ServicesH2>
                    <ServicesP>Web-based applications of software Programs customized for your business needs</ServicesP>
                    <ServicesUlWrapper>
                    <ServicesUl>
                        <ServicesLi>Payroll System</ServicesLi>
                        <ServicesLi>Loans System</ServicesLi>
                        <ServicesLi>Manufacturing ERP</ServicesLi>
                        <ServicesLi>Distribution System</ServicesLi>
                        <ServicesLi> and Reporting System</ServicesLi>
                    </ServicesUl>
                    </ServicesUlWrapper>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}></ServicesIcon>
                    <ServicesH2>WEB DESIGN & DEV</ServicesH2>
                    <ServicesP>User-friendly and responsive cross-platform websites with illustrations and animations</ServicesP>
                    <ServicesUl>
                        <ServicesLi>Online Event Platforms</ServicesLi>
                        <ServicesLi>Photobooth</ServicesLi>
                        <ServicesLi>e-Commerce website</ServicesLi>
                        <ServicesLi>E-medical Record System</ServicesLi>
                    </ServicesUl>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}></ServicesIcon>
                    <ServicesH2>MOBILE APPLICATIONS</ServicesH2>
                    <ServicesP>Optimized native iOS and Android application</ServicesP>
                    <ServicesUlWrapper>
                    <ServicesUl>
                        <ServicesLi>Delivery Platform</ServicesLi>
                    </ServicesUl>
                    </ServicesUlWrapper>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
