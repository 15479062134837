import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggle}>
            <CloseIcon />
            </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink onClick={toggle} to="home">Home</SidebarLink>
                        <SidebarLink to="about" onClick={toggle}>About Us</SidebarLink>
                        <SidebarLink to="services" onClick={toggle}>Services</SidebarLink>
                    </SidebarMenu>         
                </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
