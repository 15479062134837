import styled from "styled-components"
import { Link } from "react-scroll"

export const FooterContainer = styled.footer`
background-image: linear-gradient(to right, #8051a2 , #6743a5  );
`

export const FooterWrapper = styled.div`
padding: 48px 24px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 1100px;
margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
display:flex;
justify-content:center;

@media screen and (max-width: 820px){
    padding-top:32px;
}
`
export const FooterLinksWrapper = styled.div`
display:flex;

@media screen and (max-width: 820px){
    flex-direction:column;
    justify-content: center;
}
`
export const FooterLinkItems = styled.div`
display:flex;
flex-direction:column;
align-items: flex-start;
margin:16px;
text-align: left;
box-sizing:border-box;
color:#fff;

@media screen and (max-width:768px){
    margin:0;
    width:100%;
}
`
export const FooterLinkTitle=styled.h1`
font-size:14px;
margin-bottom:16px;
`

export const FooterLink = styled(Link)`
color:#fff;
text-decoration:none;
margin-bottome:0.5rem;
font-size:14px;
font-family:Poppins;
text-transform:uppercase;
&:hover{
    color: #000;
    transition: 0.3s ease-out;
}
@media screen and (max-width:768px){
    margin:0;
    width:100%;
    text-align: center;
}
`

export const SocialMedia=styled.section`
max-width:1000px;
width:100%;
`

export const SocialMediaWrap = styled.div`
display:flex;
justify-content:center;
align-items:center;
max-width:1100px;
margin 40px auto 0 auto;

@media screen and (max-width:820px){
    flex-direction:column;
}
`

export const WebsiteRights = styled.small`
color:#fff;
margin-bottom:16px;
font-family:Poppins;

@media screen and (max-width:768px){
    text-align: center;
}
`

export const SocialIcons = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:240px;
`
export const SocialIconLink =styled.a`
color:#fff;
font-size:24px;
`

export const Img = styled.img`
height: 100%;
width: 160px;
`;