import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { FooterContainer, FooterWrapper, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink,
SocialMedia,SocialMediaWrap, SocialIcons, SocialIconLink, WebsiteRights, Img } from './FooterElements'
import logo from '../../images/logo2.png'
import {MdEmail} from 'react-icons/md'
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
            <SocialMedia>
            <SocialMediaWrap><Img src={logo}/></SocialMediaWrap>
            </SocialMedia>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle></FooterLinkTitle>
                            <FooterLink to='home'>Home</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                            <FooterLinkTitle></FooterLinkTitle>
                            <FooterLink to='services'>Services</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                            <FooterLinkTitle></FooterLinkTitle>
                            <FooterLink to='/'>Contact Us</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                            <FooterLinkTitle></FooterLinkTitle>
                            <FooterLink to='/'>Privacy Policy</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                            <FooterLinkTitle></FooterLinkTitle>
                            <FooterLink to='/'>Terms & Conditions</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
            <SocialMediaWrap>
                <SocialIcons>
                    <SocialIconLink href="https://www.facebook.com/gucorlovely01/" target="_blank" aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                        <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                        <FaTwitter />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
                        <FaLinkedin />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="Email">
                        <MdEmail />
                    </SocialIconLink>
                </SocialIcons>
                
            </SocialMediaWrap>
            </SocialMedia>
            
            <SocialMedia>
            <SocialMediaWrap><WebsiteRights> Copyright © {new Date().getFullYear()} Purple GIANT SOLUTIONS. All rights reserved.</WebsiteRights></SocialMediaWrap>
            </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
         
         

        
    )
}

export default Footer
