import styled from "styled-components";

export const ServicesContainer = styled.div`
width: 100%;
height:800px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #fff;
margin-top:0;
margin-bottom:100px;
@media screen and (max-width: 768px){
    height: 2000px;
}
@media screen and (max-width: 640px){
    height: 2200px;
    margin-bottom:0px;
}
`;

export const ServicesWrapper=styled.div`
max-width:1200px;
margin:0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
grid-gap: 16px;
padding:0 50px;
@media screen and (max-width:1000px){
    grid-template-columns: 1fr 1fr;
}
@media screen and (max-width:768px){
    grid-template-columns: 1fr;
    padding:0 20px;
}
@media screen and (max-width: 640px){
    max-width: 1300px;
}
`;

export const ServicesCard = styled.div`
background:#fff;
display: flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
border-radius: 10px;
height:550px;
padding:30px;
box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
transition: all 0.2s ease-in-out;
font-size:25px;
font-family: Avenir LT Pro;
font-weight:35 Light;
&:hover{
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor:pointer;
}
@media screen and (max-width: 640px){
    max-width: 250px;
    margin-top:20px;
}
`;
export const ServicesIcon = styled.img`
height: 160px;
width: 160px;
margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
 font-size:45px;
 font-family: Avenir LT Pro;
 font-weight:900;
 color:#6743a5 ;
 margin-bottom: 64px;
 text-transform: uppercase;
 @media screen and (max-width: 480px){
     font-size:2rem;
 }
`;

export const ServicesH2 = styled.h2`
 font-size: 1.3rem;
 margin-bottom:10px;
 color:#666666;
`;

export const ServicesP = styled.p`
font-size: 1.1rem;
text-align: center;
color:#7a7979;
flex-shrink:4;
white-pace: pre-wrap;
overflow-wrap: break-word;
`;

export const ServicesUl = styled.ul`
list-style-type:disc;
line-height:130%;
color:#7a7979;
`
export const ServicesLi = styled.li`
font-size:1.1rem;
`
export const ServicesUlWrapper = styled.div`
width:230px;
`