import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom';


export const SidebarContainer = styled.aside`
background-image: linear-gradient(to right, #8051a2, #6743a5    );
position: fixed;
z-index:999;
width: 100%;
height:100%;
display: grid;
align-items: center;
left:0;
transition: 0.3s ease-in-out;
top:0;
opacity:${({isOpen})=> (isOpen ? '100%' : '0')};
top: ${({isOpen})=> (isOpen ? '0' : '-100%')};
z-index:${({isOpen})=> (isOpen ? '999' : '-1')};

`;

export const CloseIcon = styled(FaTimes)`
color: #fff;
`;

export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`;

export const SidebarWrapper = styled.div`
color: #fff;

`;

export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(6, 80px);
text-align: center;
padding:100px;
@media screen and (max-width:480px){
grid-template-rows: repeat(6, 60px);
}
`;


export const SidebarLink = styled(LinkS)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
text-decoration: none;
color: #fff;
cursor: pointer;
font-family:Poppins;


&:hover{
    color:#000;
    transition: 0.2s ease-in-out;
}

`;

export const SideBtnWrap =styled.div`
 display: flex;
 justify-content:center;
`
